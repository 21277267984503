<template>
  <div>
    <AppSubHeader
      link='pagos'
      title='Mi Primer Pago'
    />
    <main class="m-content">

      <div class="row">
        <div class="col-md-6">
          <!-- Begin Cuotas -->
          <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    Cuotas por pagar
                  </h3>
                </div>
              </div>
            </div>
            <div class="m-portlet__body">

              <table
                class="table m-table m-table--head-bg-warning w-100"
                id="table-fees"
              >
                <thead>
                  <tr>
                    <th
                      title="Pagar antes de"
                      data-field="fecha"
                    >Pagar antes de</th>
                    <th
                      title="Cuota"
                      data-field="fecha"
                    >Cuota</th>
                    <th
                      title="Monto"
                      data-field="monto"
                      class="text-right"
                    >Monto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="fee in fees"
                    :key="fee.id"
                  >
                    <td>{{fee.fecha}}</td>
                    <td>{{fee.numero}}</td>
                    <td class="text-right">{{fee.saldo | currency}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- End Cuotas -->
        <!-- Begin Pagos -->
        <div class="col-md-6">
          <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    Pagos realizados
                  </h3>
                </div>
              </div>
            </div>
            <div class="m-portlet__body">

              <table
                class="table m-table m-table--head-bg-success w-100"
                id="payments"
              >
                <thead>
                  <tr>
                    <th
                      title="Fecha de pago"
                      data-field="fecha"
                    >Fecha de pago</th>
                    <th
                      title="Canal de pago"
                      data-field="fecha"
                    >Canal de pago</th>
                    <th
                      title="Monto"
                      data-field="monto"
                      class="text-right"
                    >Monto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="payment in payments"
                    :key="payment.external_id"
                  >
                    <td>{{payment.paid_at}}</td>
                    <td>{{payment.channel}}</td>
                    <td class="text-right">{{payment.amount | currency}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- End Pagos  -->
    </main>
  </div>
</template>

<script>
import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import PaymentButton from "@/components/ui/PaymentButton.vue";
import PaymentWidget from "@/components/payment/PaymentWidget.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Payment",
  components: {
    AppSubHeader,
    PaymentButton,
    PaymentWidget,
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({ payments: state => state.payments.all, feeList: state => state.fees.all }),
    ...mapGetters({
      havePayments: "payments/havePayments",
      fees: "fees/pendingFees",
      next_payment: "fees/nextFee"
    }
    ),
  },
  created () {
    if (this.payments.length < 1) {
      this.$store.dispatch("payments/getAllPayments");
    }
    if (this.feeList.length < 1) {
      this.$store.dispatch("fees/getAllFees");
    }

  },
};
</script>